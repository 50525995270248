
      <!-- <div style="margin-left:{{gvars['center-width']}}px; width:80%"> -->
       <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >
          <div ngxSlickItem class="slide" style="margin-top:40px">
                <div class="flex-column" *ngFor="let card of ['low', 'high', 'ignored']" style="margin-top:30pxs">
                    <div class="flex-row " >
                           <img  src="{{image}}" *ngFor="let image of images[card]" class="cards-spacing">
                    </div>
                </div>
            </div>
            <div ngxSlickItem class="slide" style="margin-top:40px">
              <div class="flex-column" style="margin-top:30pxs">
                  <div class="flex-row"  style="margin-top:20px">
                         <img  src="{{image}}" *ngFor="let image of images['high_4']" class="cards-spacing">
                  </div>
              </div>
            </div>
            <div ngxSlickItem class="slide" style="margin-top:40px">
              <div class="flex-column" style="margin-top:30pxs">
                  <div class="flex-row"  style="margin-top:20px">
                         <img  src="{{image}}" *ngFor="let image of images['low_4']" class="cards-spacing">
                  </div>
              </div>
            </div>
            <div ngxSlickItem class="slide" style="margin-top:40px">
              <div class="flex-column" *ngFor="let card of ['high_4', 'low_4']" style="margin-top:30pxs">
                  <div class="flex-row " style="margin-top:20px" >
                         <img  src="{{image}}" *ngFor="let image of images[card]" class="cards-spacing">
                  </div>
              </div>
            </div>
            <div ngxSlickItem class="slide" style="margin-top:40px">
              <div class="flex-column" *ngFor="let card of ['high', 'low']" style="margin-top:30pxs">
                  <div class="flex-row"  style="margin-top:20px" >
                         <img  src="{{image}}" *ngFor="let image of images[card]" class="cards-spacing">
                  </div>
              </div>
            </div>
            <div ngxSlickItem class="slide" style="margin-top:40px">
                  <div class="flex-row"  style="margin-top:20px" >
                         <img  src="{{image}}" *ngFor="let image of images['ignored']" class="cards-spacing">
                  </div>
            </div>
      </ngx-slick-carousel>

      <div class="flex" style="margin-top:100px">
        <!-- <button mat-button style="margin-left:260px;" [color]="'primary'" (click)="clicked('reverse')" class="scale2" *ngIf="page>0"> <mat-icon> arrow_backward </mat-icon> </button>
        <button mat-button style="margin-left:{{fmargin()}}px;" [color]="'primary'" (click)="clicked('forward')" class="scale2"> <mat-icon> arrow_forward </mat-icon> </button> -->
        <mat-icon style="margin-left:260px;" [color]="'primary'" (click)="clicked('reverse')" class="scale2" *ngIf="page>0"> arrow_backward </mat-icon>
        <mat-icon style="margin-left:{{fmargin()}}px" [color]="'primary'" (click)="clicked('forward')" class="scale2" > arrow_forward </mat-icon>

      </div>
