import { Component, OnInit} from '@angular/core';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import {globalVars} from '../../services/global.service';

@Component({
  selector: 'anms-mobile-prediction-overview',
  templateUrl: './prediction.component.html',
  animations: [
    CommonListAnimations.drop
  ]
})
export class PredictionOverviewComponent {
  images={
    num: {
      low:'Five',
      high: 'Five',
      ignored: 'Three'
    },
    low:[
    '/assets/cards/2C',
    '/assets/cards/3D',
    '/assets/cards/4H',
    '/assets/cards/5S',
    '/assets/cards/6C'
   ],
   high:['/assets/cards/10C',
         '/assets/cards/JD',
         '/assets/cards/QH',
         '/assets/cards/KS',
         '/assets/cards/AC'],
   ignored:['/assets/cards/7C',
               '/assets/cards/8D',
               '/assets/cards/9S'],
       };
  show=false;
  constructor() {}
  roundNumber(n1, n2) {
    return (n1/n2).toFixed(2);
  }
  stack= {
    high:[],
    low: []
  };
  highLowRatio='1.00';
  lowHighRatio='1.00';
  cards = {
    high:5,
    low:5
  }
  xclicked(key, op){
    if (op=='-') {
      this.stack[key].push(this.images[key].splice(0,1)[0]);
      this.cards[key] -=1;
    } else {
      this.images[key].splice(0,0,this.stack[key].pop())
      this.cards[key] +=1;

    }
    this.lowHighRatio = this.roundNumber(this.cards.low, this.cards.high);
    this.highLowRatio = this.roundNumber(this.cards.high, this.cards.low);
  }
  ngOnInit() {
    setTimeout(() => {
      const image=globalVars['image'];
      for (const key in this.images) {
        if (key=='num') continue;
        this.images[key] = this.images[key].map(img=> `${img}${image.dims}.png`);
      }
      this.show=true;
    }, 100);
  }

}
